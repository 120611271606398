import React from 'react'
import { Link } from 'react-router-dom';
import MobbileLogo from "../assets/mandi.png"
import DownloadPlayStore from "../assets/playStore.png"
import DownlaodAppStore from "../assets/appStore.png"

const TopHomeSection = () => {
    return (
        <div className='container'>
          <div className="row">
      
            <div className="content">
              <h1 className='section-heading'>Pakistan's first nationwide<span> farming trade platform.</span></h1>
              <p className='section-para'>Pakistan’s first ever and the most comprehensive online trading platform for agriculture, food, grains and fresh produce that connects thousands of wholesale buyers and sellers across Pakistan.</p>
              <div className='downlaod-button'>
                <Link to="https://play.google.com/store/apps/details?id=com.mandilinks" target='_blank'>
                <img src={DownloadPlayStore} alt="Downlaod PlayStore"/>
                </Link>
                <Link to='https://apps.apple.com/us/app/mandilinks/id1642529292' target='_blank'>
                <img src={DownlaodAppStore} alt="Downlaod appStore"/>
                </Link>
              </div>
              </div>
      
              <div className='homePage-logo'>
                <img src={MobbileLogo} />
              </div>
          </div>
        </div>
        )
}

export default TopHomeSection