import { initializeApp } from "firebase/app";
import { getAuth, setPersistence, browserSessionPersistence } from "firebase/auth";
import { getFirestore, serverTimestamp } from "firebase/firestore";
import  {getStorage} from "firebase/storage"; 


const firebaseConfig = {
  apiKey: "AIzaSyBt-2B3FP4b2SncUq1JahhT7cl7xKhK75U",
  authDomain: "mandilinks-webpage.firebaseapp.com",
  projectId: "mandilinks-webpage",
  storageBucket: "mandilinks-webpage.appspot.com",
  messagingSenderId: "429129973657",
  appId: "1:429129973657:web:35ecec63da26bca9615d08",
  measurementId: "G-9VXNR1NH69"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage()

setPersistence(auth, browserSessionPersistence);
export { auth, db, serverTimestamp, storage };