import React from 'react'
import NavLinks from "./NavLinks"
import { NavLink } from 'react-router-dom'
import SocialLink from './SocialLink';
import "./Header.css";
import Logo from "../assets/main-logo.svg"


const Header = () => {
  return (
    <header>
      <NavLink to="/">
        <img src={Logo} alt="logo" />
      </NavLink>
      <NavLinks />
      <SocialLink />
    </header>
  )
}


export default Header