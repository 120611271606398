import React, { useState } from "react";
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import "../components/Blog.css";
import PasswordModal from "../components/PasswordModal";
import { db, serverTimestamp, storage } from "../FirebaseConfig";
import { addDoc, collection } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage"; // Import getDownloadURL
import { v4 } from "uuid";

const AddBlogScreen = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [blogContent, setBlogContent] = useState("");
  const [uploadImage, setUploadImage] = useState(null);
  const [passwordModalOpen, setPasswordModalOpen] = useState(true);
  const [language, setLanguage] = useState("english"); // Default language is English
  const navigate = useNavigate(); // Initialize navigate

  const handlePasswordSuccess = () => {
    setPasswordModalOpen(false);
  };

  const addBlog = async () => {
    if (title === "" || uploadImage === null) {
      return;
    }
  
    try {
      // Upload image to Firebase Storage
      const imageRef = ref(storage, `images/${uploadImage.name + v4()}`);
      await uploadBytes(imageRef, uploadImage);
  
      // Get the download URL of the uploaded image
      const imageUrl = await getDownloadURL(imageRef);
  
      // Create blog data with the image URL
      const blogData = {
        title: title,
        description: description,
        imageUrl: imageUrl, // Include the imageUrl property
        blogContent: blogContent,
        createdAt: serverTimestamp(),
      };
  
      // Add blog data to Firestore
      await addDoc(collection(db, "blogs"), blogData);
      console.log("Blog added successfully");
  
      // Reset form fields
      setTitle("");
      setDescription("");
      setBlogContent("");
      setUploadImage(null);
  
      // Redirect to another page if needed
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  

  return (
    <div className="add-blog-container">
      {passwordModalOpen ? (
        <PasswordModal onSuccess={handlePasswordSuccess} />
      ) : (
        <>
          <h2 className="add-blog-heading">
            {language === "english" ? "Add New Blog" : "نیا بلاگ  شامل کریں"}
          </h2>
          <div className="input-group">
            <input
              type="text"
              className="blog-input"
              placeholder={`${
                language === "english" ? "blog Title" : "بلاگ  کا عنوان"
              }`}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <input
              type="text"
              className="blog-input"
              placeholder={`${
                language === "english" ? "blog description" : "بلاگ  کا تفصیل"
              }`}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <textarea
              className="blog-input blog-content"
              placeholder={`${
                language === "english" ? "blog Content" : "بلاگ  کا اضافی"
              }`}
              value={blogContent}
              onChange={(e) => setBlogContent(e.target.value)}
            />
            <input
              type="file"
              className="blog-input"
              placeholder={`${
                language === "english" ? "upload Image" : "بلاگ کی تصویر"
              }`}
              onChange={(e) => setUploadImage(e.target.files[0])} 
            />
          </div>

          <button
            className="language-btn"
            onClick={() =>
              setLanguage(language === "english" ? "urdu" : "english")
            }
          >
            {language === "english" ? "Switch to Urdu" : "Switch to English"}
          </button>

          <button className="add-blog-btn" onClick={addBlog}>
            {language === "english" ? "Add Blog" : "بلاگ  شامل کریں"}
          </button>
        </>
      )}
    </div>
  );
};

export default AddBlogScreen;
