import React, { useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../FirebaseConfig";

const PasswordModal = ({ onSuccess }) => {
  const [password, setPassword] = useState("");
  const [fetchPassword, setFetchPassword] = useState("");

  useEffect(() => {
    getFetchPassword();
  }, []);

  const getFetchPassword = async () => {
    try {
      const settingsCollectionRef = collection(db, "setting");
      const settingsSnapshot = await getDocs(settingsCollectionRef);
      settingsSnapshot.forEach((doc) => {
        const settingsData = doc.data();
        setFetchPassword( doc.id, "=>", settingsData);
    });
    } catch (error) {
      console.error("Error fetching password: ", error);
    }
  };

  const handleSubmitPassword = () => {
    if (password === fetchPassword) {
      onSuccess();
    } else {
      alert("Incorrect password, please try again.");
    }
  };

  return (
    <div className="add-blog-container">
      <h2 className="add-blog-heading">Please enter password to continue</h2>
      <input
        type="password"
        className="blog-input"
        value={password}
        placeholder="Enter password"
        onChange={(e) => setPassword(e.target.value)}
      />
      <button  className="add-blog-btn" onClick={handleSubmitPassword}>Submit</button>
    </div>
  );
};

export default PasswordModal;
