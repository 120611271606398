import React from "react";
import { Link } from "react-router-dom";
import {
  FaFacebookF,
  FaLinkedinIn,
  FaInstagram,
} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import "./Header.css";

const SocialLink = () => {
  return (
    <nav>
      <div className="social-links">
        <Link to="https://twitter.com/mandilinksapp">
          <div className="iconBox">
            <FaXTwitter />
          </div>
        </Link>

        <Link to="https://www.facebook.com/mandilinksapp">
          <div className="iconBox">
            <FaFacebookF />
          </div>
        </Link>

        <Link to="https://www.linkedin.com/in/mandilinks-app-52268b252/">
          <div className="iconBox">
            <FaLinkedinIn />
          </div>
        </Link>

        <Link to="https://www.instagram.com/mandilinksapp/">
          <div className="iconBox">
            <FaInstagram />
          </div>
        </Link>

      </div>
    </nav>
  );
};

export default SocialLink;
