import React from "react";
import CartoonAvater from "../assets/mobileFarm.png";

const ThirdSection = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="content">
          <h1 className="section-heading big-heading" >
          Find the right Price for your produce as you can reach buyers across Pakistan.
          </h1>
          <p className="section-para">
            Dont worry about your wallet. Beause every single product That
            avalible to our app is cheap and you dont have to think too much
            about your cash flow{" "}
          </p>
          <button >Learn More</button>
        </div>

        <div className="homePage-logo">
          <img src={CartoonAvater} />
        </div>
      </div>
    </div>
  );
};

export default ThirdSection;
