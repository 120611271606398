import React from "react";
import Step1 from "../assets/step1.png";
import Step2 from "../assets/step2.png";
import Step3 from "../assets/step3.png";


const HowItWork = () => {
  return (
    <div className="container">
      

      <h1 className="section-heading steps">How It Works</h1>
      <div className="row howItWork-Section">

        <div className="process-container">
            <img className="proces-image" src={Step1} alt="Signup process" />
            <div className="process">
                <h2 className="procces-name">get started: register yourself today</h2>
            </div>
        </div>


        <div className="process-container">
            <img className="proces-image" src={Step2} alt="Signup process" />
            <div className="process">
                <h2 className="procces-name">connet with buyers & sellers</h2>
            </div>
        </div>



        <div className="process-container">
            <img className="proces-image" src={Step3} alt="Signup process" />
            <div className="process">
                <h2 className="procces-name">Buy and Sell</h2>
            </div>
        </div>



      </div>
    </div>
  );
};

export default HowItWork;
