import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import "./Header.css";
import DownloadPlayStore from "../assets/playStore.png"
import DownlaodAppStore from "../assets/appStore.png"

const NavLinks = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (isModalOpen) {
        closeModal();
      }
    };

    if (isModalOpen) {
      window.addEventListener("scroll", handleScroll);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isModalOpen]);

  return (
    <nav>
      <ul className="Nav-list">
        <li>
          <NavLink exact to="/" activeClassName="active">
            Home
          </NavLink>
        </li>
        <li>
          <NavLink to="/MarketReports" activeClassName="active">
            Market Reports
          </NavLink>
        </li>
        <li>
          <NavLink to="/blog" activeClassName="active">
            Blog
          </NavLink>
        </li>
        <li className="downloadButton">
          <a onClick={openModal}>Download App</a>
        </li>
      </ul>
      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-box">
            <span className="modal-close" onClick={closeModal}>
              &times;
            </span>{" "}
            {/* Close icon */}
            <div className="modal-content">

              <Link className='downlaod-link'
                to="https://play.google.com/store/apps/details?id=com.mandilinks"
                target="_blank"
              >
                <img src={DownloadPlayStore} alt="Downlaod PlayStore " />
              </Link>

              <Link className='downlaod-link'
                to="https://apps.apple.com/us/app/mandilinks/id1642529292"
                target="_blank"
              >
                <img src={DownlaodAppStore} alt="Downlaod appStore" />
              </Link>
            </div>
          </div>
        </div>
      )}
    </nav>
  );
};

export default NavLinks;
