import React, {useState, useEffect } from "react";
import MobbileMockup from "../assets/degitalFarm.png";
import { TiTickOutline } from "react-icons/ti";
import { Link } from "react-router-dom";
import DownloadPlayStore from "../assets/playStore.png"
import DownlaodAppStore from "../assets/appStore.png"

const SecoundHomeSection = () => {

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (isModalOpen) {
        closeModal();
      }
    };

    if (isModalOpen) {
      window.addEventListener("scroll", handleScroll);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isModalOpen]);

  return (
    <div className="container secound">
      <h1 className="section-heading">What Problem do We solve?</h1>
      <div className="row reverse">
        <div className="homePage-logo mob-mockup">
          <img src={MobbileMockup} />
        </div>

        <div className="content">

        <div className="solve-boxes">
            <div className="iconContainer">
              <TiTickOutline fontSize="25px" />
            </div>
            <div className="solve-text">
              <h2 className="sectionSubHeading" >Mandi in your Hands</h2>
              <p> Check the market prices from the ease of your home across Pakistan.</p>
            </div>
          </div>
          

          <div className="solve-boxes">
            <div className="iconContainer">
              <TiTickOutline fontSize="25px" />
            </div>
            <div className="solve-text">
              <h2 className="sectionSubHeading"> Connect with the biggest audience of Agricultural  products buyers, Sellers and Traders</h2>
              <p>You can Use this very easily wihout going mandis to far and can access smotly with single click</p>
            </div>
          </div>


          
            <button onClick={openModal}>Get the App</button>


          {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-box">
            <span className="modal-close" onClick={closeModal}>
              &times;
            </span>{" "}
            {/* Close icon */}
            <div className="modal-content">

              <Link className='downlaod-link'
                to="https://play.google.com/store/apps/details?id=com.mandilinks"
                target="_blank"
              >
                <img src={DownloadPlayStore} alt="Downlaod PlayStore " />
              </Link>

              <Link className='downlaod-link'
                to="https://apps.apple.com/us/app/mandilinks/id1642529292"
                target="_blank"
              >
                <img src={DownlaodAppStore} alt="Downlaod appStore" />
              </Link>
            </div>
          </div>
        </div>
      )}



        </div>
      </div>
    </div>
  );
};

export default SecoundHomeSection;
