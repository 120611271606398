import React from 'react'
import "./Error.css"
import { Link, NavLink } from 'react-router-dom'
const Error = () => {
  return (
    <div className='error-page'>
        <div className='error-logo'> 
            <img src="https://i.pinimg.com/564x/e3/9d/99/e39d9940b8e5bd20390b73d8a20db7ba.jpg" alt="" />
        </div>
    <div className='error-content'>
      <h1>404 - Page Not Found</h1>
      <p>Oops! It seems like the page you're looking for doesn't exist.</p>
      <NavLink to="/" className="goBack">
        go Back
      </NavLink>
    </div>

      <div className='homePage-logo'>
                <img src="https://img.freepik.com/premium-vector/illustration-vector-graphic-cartoon-character-connected_516790-223.jpg?w=826" />
       </div>


    </div>
  )
}

export default Error
