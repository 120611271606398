import React from "react";
import TopHomeSection from "../components/TopHomeSection";
import SecoundHomeSection from "../components/SecoundHomeSection";
import ThirdSection from "../components/ThirdSection";
// import AppInfo from "../components/AppInfo";
import HowItWork from "../components/HowItWork";
import AddReportsForm from "./AddReports";
import "./Main.css";
const Home = () => {
  return <>
  <TopHomeSection />
  <SecoundHomeSection />
  <HowItWork/>
  <ThirdSection />
  {/* <AppInfo /> */}
  </>
};

export default Home;
