import React from "react";
import { Link, NavLink } from "react-router-dom";
import "./Footer.css";
import {
  FaFacebookF,
  FaXTwitter,
  FaLinkedinIn,
  FaInstagram,
} from "react-icons/fa6";
import DownloadPlayStore from "../assets/playStore.png";
import DownlaodAppStore from "../assets/appStore.png";
import MobbilleTab from "../assets/mobbile&tablet.png";
import social from "../components/SocialLink"


const Footer = () => {
  return (
    <footer>
      <div>
        <div className="footerContainer">
      
          <div className="social-links">

        <Link to="https://twitter.com/mandilinksapp">
          <div className="iconBox" style={{border:"1px solid white"}}>
          <FaXTwitter color="white"/>
          </div>
        </Link>

        <Link to="https://www.facebook.com/mandilinksapp">
          <div className="iconBox" style={{border:"1px solid white"}}>
            <FaFacebookF  color="white"/>
          </div>
        </Link>

        <Link to="https://www.linkedin.com/in/mandilinks-app-52268b252/">
          <div className="iconBox" style={{border:"1px solid white"}}>
            <FaLinkedinIn  color="white"/>
          </div>
        </Link>

        <Link to="https://www.instagram.com/mandilinksapp/">
          <div className="iconBox" style={{border:"1px solid white"}}>
            <FaInstagram  color="white"/>
          </div>
        </Link>

      </div>

          <div className="footerNavLinks">
            <ul className="links">
              <li>
                <NavLink exact to="">
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink to="/blog">Blogs</NavLink>
              </li>
              <li>
                <Link
                  to="https://mandilinks.com/get-product-list/Request"
                  target="_blank"
                >
                  Privacy Policy
                </Link>
              </li>
              <li>
                <NavLink to="/MarketReports">Market Reports</NavLink>
              </li>
            </ul>
          </div>
          <div className="downlaod-app">
            <Link to="https://apps.apple.com/us/app/mandilinks/id1642529292" target="_blank">
              <img
                src={DownlaodAppStore}
                alt="Downlaod Play Store"
              />
            </Link>
            <Link to="https://play.google.com/store/apps/details?id=com.mandilinks" target="_blank">
              <img
                src={DownloadPlayStore}
                alt="Downlaod App store Store"
              />
            </Link>
          </div>
        </div>
        <div className="copyrightBox">
          <p style={{ margin: 0 }}>
            All Rights Reserved Copyright © 2024 MandiLinks
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
